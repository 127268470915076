html,
body {
  background-color: #f9f9f9 !important;
}

.ant-popover-disabled-compatible-wrapper {
  pointer-events: none;
}

.auth-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 400px;
}

.auth-container h1 {
  margin: auto;
  margin-top: 10px;
}

.auth-logo {
  width: 120px;
  height: 120px;
  margin: auto;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.center-elem-margin {
  margin: auto;
}

.help-footer {
  height: 100px;
}

.justify-text {
  text-align: justify;
  text-justify: inter-word;
}

.help-section-title {
  font-size: 1.1rem;
}

.help-section {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.intelibot-image {
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.welcome-modal-header {
  align-items: center !important;
}

.tooltip-wrapper {
  margin: 50px;
}

.tooltip-wrapper .btn[disabled] {
  pointer-events: none;
}

.tooltip-wrapper.disabled {
  cursor: not-allowed;
}