b,
strong {
  font-weight: 600;
}

#navbar-main {
  padding: 0;
  .navbar-brand {
    padding: 1rem 24px;
  }
  a.nav-link {
    padding: 0.5rem 2rem;
  }
  li.nav-item.active {
    background-color: $ig-pink;
    border-radius: 10rem;
    a.nav-link {
      span.nav-link-inner--text {
        color: white;
      }
    }
  }
}

.popover {
  cursor: pointer !important;
}

.popover-body,
.tab-pane {
  div[data-rbd-draggable-context-id] {
    position: inherit !important;
  }
}

div[data-rbd-placeholder-context-id] {
  height: 2rem !important;
}

.popover-body div[data-rbd-draggable-context-id] {
  left: 0 !important;
}

.popover {
  max-width: 100%;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5e72e4 !important;
  border-color: transparent !important;
  border-bottom: solid 2px #5e72e4 !important;
  background-color: transparent !important;
  :hover {
    background-color: transparent !important;
    border-color: transparent !important;
    border-bottom: solid 2px #5e72e4 !important;
  }
}

.nav-tabs .nav-item {
  :hover {
    background-color: transparent !important;
    border-color: transparent !important;
    border-bottom: none;
  }
}

.central-xy {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 100% !important;
}

.border-secondary {
  border: solid 1px #ccc !important;
}

.border-bottom.border-secondary {
  border: none !important;
  border-bottom: solid 1px #ccc !important;
}

.modal-backdrop.show {
  opacity: 0.46 !important;
}
